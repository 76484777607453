<template>
    <div>
        <div class="main">
            <div class="main-row">
                <div class="rowFirst-left">
                    <div class="pos-rel data h-130">
                        <div class="one"></div>
                        <div class="two"></div>
                        <div class="three"></div>
                        <div class="four"></div>
                        <div class="fs-22">{{riqidata.current_hm}}</div>
                        <div class="fs-18">{{riqidata.current_data}}<br/>
                            {{riqidata.current_w}}
                        </div>
                    </div>
                </div>
                <div class="rowFirst-right">
                    <div class="item-bg item-border h-130 notice">
                        <div class="notice-left">通<br/>知<br/>公<br/>告</div>
                        <div class="notice-right">
                            <div class="notice-right-item" v-if="key== 0" v-for="( item,key) in Announcementdata" :key="key">
                                <div class="over">{{item.content}}
                                </div>
                                <div style="width: 10%;text-align: right; margin-top: 10px">{{item.create_time}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-row pt-15">
                <div class="rowSecond-left">
                    <div class="tubiao-item h-240 pos-rel">
                        <div class="one"></div>
                        <div class="two"></div>
                        <div class="three"></div>
                        <div class="four"></div>
                        <div>近7日委托单数</div>
                        <div class="tubiao-main">
                            <div id="weituodanshu" style="width: 430px;height: 260px; margin-top: -40px"></div>
                        </div>
                    </div>
                    <div class="tubiao-item h-240 pos-rel">
                        <div class="one"></div>
                        <div class="two"></div>
                        <div class="three"></div>
                        <div class="four"></div>
                        <div>近7日采集样本数</div>
                        <div class="tubiao-main">
                            <div id="yangbenshu" style="width: 430px;height: 260px; margin-top: -40px"></div>
                        </div>
                    </div>
                    <div class="tubiao-item h-240 pos-rel">
                        <div class="one"></div>
                        <div class="two"></div>
                        <div class="three"></div>
                        <div class="four"></div>
                        <div>近7日出具报告数量</div>
                        <div class="tubiao-main">
                            <div id="baogaoshu" style="width: 430px;height: 260px; margin-top: -40px"></div>
                        </div>
                    </div>
                </div>

                <div class="rowSecond-center">
                    <div class="item-bg item-border h-748 kanban">
                        <div class="one"></div>
                        <div class="two"></div>
                        <div class="three"></div>
                        <div class="four"></div>
                        <div class="kanban-mark">智慧实验室大数据看板</div>
                        <div class="kanban-table">
                            <table>
                                <tr>
                                    <th>委托单号</th>
                                    <th>样品编号</th>
                                    <th>样品名称</th>
                                    <th>样品状态</th>
                                    <th>归档</th>
                                </tr>
                                <tr>
                                    <td rowspan="2">DX20192653</td>
                                    <td>2.1</td>
                                    <td>水质检测</td>
                                    <td><span class="daicaiji">待采集</span></td>
                                    <td rowspan="2"></td>
                                </tr>
                                <tr>
                                    <td>2.9</td>
                                    <td>水质检测</td>
                                    <td>待采集</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">DX20192653</td>
                                    <td>2.1</td>
                                    <td>水质检测</td>
                                    <td><span class="shiyanzhong">待采集</span></td>
                                    <td rowspan="3">
                                        <p>西区实验室</p>
                                        <p>归档人：泥工</p>
                                        <p>时间：2021-5-23</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.9</td>
                                    <td>水质检测</td>
                                    <td>待采集</td>
                                </tr>
                                <tr>
                                    <td>2.9</td>
                                    <td>水质检测</td>
                                    <td>待采集</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">DX20192653</td>
                                    <td>2.1</td>
                                    <td>水质检测</td>
                                    <td><span>待采集</span></td>
                                    <td rowspan="3">
                                        <p>西区实验室</p>
                                        <p>归档人：泥工</p>
                                        <p>时间：2021-5-23</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.9</td>
                                    <td>水质检测</td>
                                    <td>待采集</td>
                                </tr>
                                <tr>
                                    <td>2.9</td>
                                    <td>水质检测</td>
                                    <td>待采集</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="rowSecond-right">
                    <div class="lab h-748">
                        <div class="lab-mark">实验室</div>
                        <div class="lab-item" v-for="( item,key) in laboratorydata" :key="key">
                            <img src="http://api.tengbocn.com/upload/assets/lab-pink.png" v-if="item.laboratory_temperature == 1 || item.laboratory_humidity == 1">
                            <img src="http://api.tengbocn.com/upload/assets/lab-blue.png" v-else>
                            <div class="yichangborder" v-if="item.laboratory_temperature == 1 || item.laboratory_humidity == 1">
                                <img src="http://api.tengbocn.com/upload/assets/border-pink.png" alt=""></div>
                            <p>{{item.name}}</p>
                            <div class="wendu">
                                <div>
                                    <img src="http://api.tengbocn.com/upload/assets/wendu.png">
                                    <span v-if="item.laboratory_temperature == 1" class="pink">{{item.temperature}}℃</span>
                                    <span v-else>{{item.temperature}}℃</span>
                                </div>
                                <div v-if="item.laboratory_humidity == 1">
                                    <img src="http://api.tengbocn.com/upload/assets/shidu.png">
                                    <span v-if="item.humidity == '无数值'" class="pink">{{item.humidity}}</span>
                                    <span class="pink" v-else>{{item.humidity}}%</span>
                                </div>
                                <div v-else>
                                    <img src="http://api.tengbocn.com/upload/assets/shidu.png">
                                    <span>{{item.humidity}}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-row">
                <div>
                    <div class="yiqi-card-one">
                        <div class="mark-item">
                            <div class="color-mark mark-blue"></div>
                            空闲
                        </div>
                        <div class="mark-item">
                            <div class="color-mark mark-green"></div>
                            使用
                        </div>
                        <div class="mark-item">
                            <div class="color-mark mark-pink"></div>
                            维修
                        </div>
                        <div class="mark-item">
                            <div class="color-mark mark-gray"></div>
                            送检
                        </div>
                        <div class="mark-item">
                            <div class="color-mark mark-yellow"></div>
                            借出
                        </div>

                    </div>
                </div>

                <div class="yiqi-card">
                    <div class="yiqi-list" v-for="( item,key) in apparatusdata" :key="key">
                        <img src="http://api.tengbocn.com/upload/assets/yiqi-blue.png" v-if="item.used_status===1">
                        <img src="http://api.tengbocn.com/upload/assets/yiqi-grey.png" v-if="item.used_status===3">
                        <img src="http://api.tengbocn.com/upload/assets/yiqi-red.png" v-if="item.used_status===5">
                        <img src="http://api.tengbocn.com/upload/assets/yiqi-yellow.png" v-if="item.used_status===4">
                        <img src="http://api.tengbocn.com/upload/assets/yiqi-green.png" v-if="item.used_status===2">
                        <div style="font-size: 14px; text-align: center; width: 130px;overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;">{{item.name}}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

    export default {
        components: {},
        data() {
            return {
                Announcementdata: [],
                laboratorydata: [],
                apparatusdata: [],
                riqidata: ''
            }
        },
        mounted() {
            this.Announcement()//获取大屏通知数据
            this.draw7Day()
            this.sample7Day()
            this.baogao7Day()
            this.Dp_data()
        },
        methods: {

            Dp_data() {
                this.$sa0.post({
                    url: this.$api('Dp_data'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.riqidata = response.data;
                            setTimeout(() => {
                                this.Dp_data()
                            }, 1000 * 60)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            draw7Day() {
                this.$sa0.post({
                    url: this.$api('Daping_task'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('weituodanshu');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data.data;
                            console.log(response.data.data)
                            option = {
                                title: {
                                    text: '',
                                },
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'cross',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                xAxis: {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: data[0],
                                    //坐标轴颜色
                                    axisLine: {
                                        lineStyle: {
                                            color: '#10417d'
                                        }
                                    },
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#00e4ff' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#001268' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                                textStyle: {
                                    color: '#1fe4dd',
                                },
                                series: [
                                    {
                                        data: data[1],
                                        type: 'line',
                                        areaStyle: {},
                                        symbolSize: 2, //拐点圆的大小
                                        itemStyle: {
                                            normal: {
                                                lineStyle: {
                                                    width: 1,
                                                    type: 'solid',  //'dotted'虚线 'solid'实线
                                                    color: '#00e4ff'
                                                }
                                            },

                                        },
                                    }
                                ],
                            };
                            option && myChart.setOption(option);


                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })


            },


            sample7Day() {
                this.$sa0.post({
                    url: this.$api('Daping_specitem'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('yangbenshu');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data.data;
                            console.log(response.data.data)
                            option = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'cross',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                xAxis: {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: data[0],
                                    //坐标轴颜色
                                    axisLine: {
                                        lineStyle: {
                                            color: '#10417d'
                                        }
                                    },
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#00e4ff' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#001268' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },

                                textStyle: {
                                    color: '#1fe4dd',
                                },
                                series: [
                                    {
                                        data: data[1],
                                        type: 'line',
                                        areaStyle: {},
                                        symbolSize: 2, //拐点圆的大小
                                        itemStyle: {
                                            normal: {
                                                lineStyle: {
                                                    width: 1,
                                                    type: 'solid',  //'dotted'虚线 'solid'实线
                                                    color: '#00e4ff'
                                                }
                                            }
                                        },
                                    }
                                ],
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            baogao7Day() {
                this.$sa0.post({
                    url: this.$api('Daping_report'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('baogaoshu');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data.data;
                            console.log(response.data.data)
                            option = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'cross',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                xAxis: {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: data[0],
                                    //坐标轴颜色
                                    axisLine: {
                                        lineStyle: {
                                            color: '#10417d'
                                        }
                                    },
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#00e4ff' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#001268' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },

                                textStyle: {
                                    color: '#1fe4dd',
                                },
                                series: [
                                    {
                                        data: data[1],
                                        type: 'line',
                                        areaStyle: {},
                                        symbolSize: 2, //拐点圆的大小
                                        itemStyle: {
                                            normal: {
                                                lineStyle: {
                                                    width: 1,
                                                    type: 'solid',  //'dotted'虚线 'solid'实线
                                                    color: '#00e4ff'
                                                }
                                            }
                                        },
                                    }
                                ],
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })


            },


            Announcement() {
                this.$sa0.post({
                    url: this.$api('Get_daping'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Announcementdata = response.data.result.announcement;
                            this.laboratorydata = response.data.result.laboratory;
                            this.apparatusdata = response.data.result.apparatus;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
        }
    }
</script>


<style scoped>
    .main{
        box-sizing: border-box;
        margin: 0 auto;
        width: 1920px;
        height: 1080px;
        background: url("../../../public/assets/daping-bg.jpg") top center no-repeat;
        background-size: 100% 100%;
        padding: 15px;
        color: #fff;
        font-size: 18px;
        }

    .main-row{
        display: flex;
        }

    .h-130{ height: 130px}

    .h-240{ height: 240px; margin-bottom: 15px}

    .h-748{ height: 750px}

    .pt-15{ padding-top: 15px}

    /******************************************第一行******************************************/
    .rowFirst-left{
        width: 22%;
        height: 130px;
        }

    .rowFirst-right{
        width: 78%;
        padding-left: 15px;
        }

    .item-bg{
        position: relative;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
        width: 100%;
        }

    .item-border{border: solid 1px #0d6e94;}

    .one{ position: absolute; top: 0; left: 0; width: 10px; height: 10px; border-top: solid 1px #03fcff;border-left: solid 1px #03fcff}

    .two{ position: absolute; top: 0; right: 0; width: 10px; height: 10px; border-top: solid 1px #03fcff;border-right: solid 1px #03fcff}

    .three{ position: absolute; bottom: 0; left: 0; width: 10px; height: 10px; border-bottom: solid 1px #03fcff;border-left: solid 1px #03fcff}

    .four{ position: absolute; bottom: 0; right: 0; width: 10px; height: 10px; border-bottom: solid 1px #03fcff;border-right: solid 1px #03fcff}


    /******************************************日期时间******************************************/
    .pos-rel{position: relative;}

    .data{
        display: flex;
        justify-content: space-around;
        background: rgba(2, 20, 103, 0.58);
        box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.2);
        padding: 25px;
        }

    .fs-22{ font-size: 48px; text-align: center; line-height: 1.6}

    .fs-18{ font-size: 18px; text-align: center; padding-top: 15px}

    /******************************************公告******************************************/
    .notice{
        background: url("../../../public/assets/notice.png") left no-repeat;
        background-size: auto 100%;
        display: flex;
        justify-content: space-around;
        }

    .notice-left{ width: 1.5%; font-size: 20px; text-align: center; line-height: 1.2; padding-top: 25px; font-size: 16px;}

    .notice-right{ width: 95%;}

    .notice-right-item{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.05);
        border: solid 1px rgba(13, 110, 148, 0.33);
        line-height: 1;
        padding: 10px 20px;
        height: 110px;
        }

    /*.over{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}*/
    .over{ flex: 1; line-height: 1.6}


    /******************************************第二行******************************************/
    .rowSecond-left{ width: 22%}

    .rowSecond-center{ width: 63%;padding-left: 15px;}

    .rowSecond-right{ width: 15%; padding-left: 15px;}

    /*********************第二行 左侧图表*********************/
    .tubiao-item{
        background: rgba(2, 19, 103, 0.7);
        box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.2);
        padding: 10px;
        font-size: 16px;
        }

    .tubiao-main{ padding: 15px 0}

    /*********************第二行 大数据看板*********************/
    .kanban{
        background: rgba(2, 19, 103, 0.7);
        border: solid 1px #0d6e94;
        }

    .kanban-mark{
        height: 63px;
        /*background: url("http://api.lims.tengbocn.com/upload/assets/mark.png") center top no-repeat;*/

        background: url("../../../public/assets/mark.png") top center no-repeat;
        background-size: auto 100%;
        text-align: center;
        font-size: 22px;
        line-height: 60px;
        }

    .kanban-table{padding: 25px 15px}

    table{
        width: 100%;
        border-top: solid 1px #1c4aa1;
        border-left: solid 1px #1c4aa1;
        }

    table td, table th{
        border-right: solid 1px #1c4aa1;
        border-bottom: solid 1px #1c4aa1;
        padding: 25px 15px;
        line-height: 1;
        vertical-align: middle;
        }

    table th{ background: #01105b}

    table tr:hover{ background: none}

    .daicaiji{ color: #00e4ff}

    .shiyanzhong{ color: #fcff00}

    /*********************第二行 实验室*********************/
    .lab{
        background: rgba(2, 19, 103, 0.25);
        border: solid 1px rgba(13, 110, 148, 0.47);
        }

    .lab-mark{
        border-bottom: solid 1px rgba(13, 110, 148, 0.47);
        background: url("../../../public/assets/icon-lab.png") 15px 18px no-repeat;
        font-size: 16px;
        padding: 15px 0 15px 45px;
        }

    .lab-item{
        margin: 32px auto 0 auto;
        width: 255px;
        height: 187px;
        background: url("../../../public/assets/border-blue.png") center no-repeat;
        padding: 20px;
        text-align: center;
        position: relative;
        }

    .yichangborder{ position: absolute; top: 0; left: 0; right: 0; bottom: 0}

    .lab-item img{display: inline-block; }

    .lab-item p{ font-size: 22px; padding: 10px 0 10px 0; margin-bottom: 0}

    .lab-item.yichang{background: url("../../../public/assets/border-pink.png") center no-repeat;}

    .pink{color: #ff525d}

    .wendu{
        display: flex;
        justify-content: space-around;
        }

    .wendu img{ margin-right: 10px}

    .wendu span{ font-size: 20px; font-weight: normal; vertical-align: middle}


    /******************************************第三行******************************************/
    .yiqi-card-one{
        width: 230px;
        height: 142px;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        }

    .mark-item{padding: 5px 0 5px 10px; font-size: 16px}

    .color-mark{
        display: inline-block;
        width: 34px;
        height: 19px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 3px;
        margin-right: 5px;
        vertical-align: -4px;
        }

    .mark-blue{ border: solid 1px #003ab9}

    .mark-green{ border: solid 1px #00a083}

    .mark-pink{ border: solid 1px #9c3656}

    .mark-gray{ border: solid 1px #535770}

    .mark-yellow{ border: solid 1px #9c9e1d}

    .yiqi-card{
        flex: 1;
        height: 142px;
        padding: 15px;
        background: rgba(2, 19, 103, 0.48);
        display: flex;
        }

    .yiqi-card img{ display: inline-block; margin-bottom: 5px}

    .yiqi-list{ margin-right: 20px}


</style>
